import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import banner from '../assets/images/hero.jpg';
const IndexPage = () => <Layout>
	<article id="main">
		<header>
			<h2>Price list</h2>
			<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>
		</header>
		<section className="wrapper style5">
			<div className="inner">

				
				<section>
					<div className="table-wrapper">
						<table>
							<thead>
								<tr>
									<th>Period</th>
									<th>Guests</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jan 1 - May 31</td>
									<td>2</td>
									<td>$29.99</td>
								</tr>
								<tr>
									<td>Jan 1 - May 31</td>
									<td>3</td>
									<td>$35.99</td>
								</tr>
								<tr>
									<td>Jun 1 - Jun 31</td>
									<td>2</td>
									<td>$49.99</td>
								</tr>
								<tr>
									<td>Jun 1 - Jun 31</td>
									<td>3</td>
									<td>$59.99</td>
								</tr>
								<tr>
									<td>Jul 1 - Sep 31</td>
									<td>2</td>
									<td>$59.99</td>
								</tr>
								<tr>
									<td>Jul 1 - Sep 31</td>
									<td>3</td>
									<td>$69.99</td>
								</tr>
								<tr>
									<td>Oct 1 - Dec 31</td>
									<td>2</td>
									<td>$29.99</td>
								</tr>
								<tr>
									<td>Oct 1 - Dec 31</td>
									<td>3</td>
									<td>$35.99</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</section>
	</article>

	<section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Book your stay with us</h2>
          <p>
            To inquire about the avilability please contact us using the link beside.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="mailto:nikola.pejcic.to@gmail.com" className="button fit primary">
              Contact us
            </a>
          </li>
          <li>
            <a href="/Pricelist" className="button fit">
              Price list
            </a>
          </li>
        </ul>
      </div>
    </section>

</Layout>;

export default IndexPage;
